let enterType = [
    "有限公司",
    "有限责任公司",
    "私营企业",
    "个体户",
    "国有企业",
    "股份有限公司",
    "股份合作企业",
    "合资企业",
    "集体企业",
    "联营企业",
    "合伙企业"
]

let enterHy = [{
    "children": [{
        "id": "fafbc17e4e9f4ae68a2c57d9c951f3c6",
        "name": "房地产"
    }, {
        "id": "6bffc38b9d994670be0fbc539602e4a9",
        "name": "建筑/建材/工程"
    }, {
        "id": "958fd7336a0f45fcab545418a972168d",
        "name": "家居/室内设计/装潢"
    }, {
        "id": "a73ecb1e5d6f4ef69b3ba989e1eb57b8",
        "name": "物业管理/商业中心"
    }, {
        "id": "2d4c57f2388042689ef747f9568de273",
        "name": "中介服务"
    }, {
        "id": "09e4c4793165454582c891519f89d1da",
        "name": "租赁服务"
    }],
    "id": "68abfb3edbf0424c9f26000809e78c4f",
    "name": "建筑/房地产"
}, {
    "children": [{
        "id": "1947f1c3c8db4fad842d356972d8f253",
        "name": "贸易/进出口"
    }, {
        "id": "ab4fd4684e0b4cc38389070252681a7f",
        "name": "批发/零售"
    }, {
        "id": "33272eb6f35b4f04b1ed27dddea67085",
        "name": "快速消费品(食品、饮料、化妆品)"
    }, {
        "id": "03dbd09864104282aef75e6fbf07ee4b",
        "name": "服装/纺织/皮革"
    }, {
        "id": "b0ad47409ca44842bac0a5144fa6899c",
        "name": "家具/家电/玩具/礼品"
    }, {
        "id": "a4c05e89320746dabac6a79a609ff8db",
        "name": "奢侈品/收藏品/工艺品/珠宝"
    }, {
        "id": "c0f68f2266cd494c92ef09f0f34bcf02",
        "name": "办公用品及设备"
    }, {
        "id": "98cd5c7dee074075a847dae270a61b66",
        "name": "机械/设备/重工"
    }, {
        "id": "040ca529bf354b56a96107a09e174d2e",
        "name": "汽车"
    }, {
        "id": "1f3cb8c512c5468192a12acb0b8100fd",
        "name": "汽车零配件"
    }],
    "id": "328c82355d324bb4baf98e825d9fb9b2",
    "name": "贸易/消费/制造/营运"
}, {
    "children": [{
        "id": "c7175ae9805749648cf876f06117b4a7",
        "name": "石油/化工/矿产/地质"
    }, {
        "id": "980bdfa0485f45e0b4c93710d6b5eae7",
        "name": "采掘业/冶炼"
    }, {
        "id": "5c7e52cbeb1549769688854fb11b0cfb",
        "name": "电气/电力/水利"
    }, {
        "id": "24988d951b39425aae112b4f28edb14d",
        "name": "新能源"
    }, {
        "id": "f1fd23da91244d02ac05785a969f5603",
        "name": "原材料和加工"
    }, {
        "id": "c80fe30ab85a4889baae0ec2463158fc",
        "name": "环保"
    }],
    "id": "9967af0357c9498684dd451afe718710",
    "name": "能源/环保/化工"
}, {
    "children": [{
        "id": "59b1a401c562494bb941b96d7d0d7f1f",
        "name": "餐饮业"
    }, {
        "id": "b81adb5928ae4573abb9bd88b854b722",
        "name": "酒店/旅游"
    }, {
        "id": "722f46035abe437792a987d9987e5cd6",
        "name": "娱乐/休闲/体育"
    }, {
        "id": "5361d739eb76481c88aabcc7542e1a9f",
        "name": "美容/保健"
    }, {
        "id": "38296414987e45b1a5ab8c9614baec22",
        "name": "生活服务"
    }],
    "id": "a7fb04f9e89b4d01b28a171c82a3c436",
    "name": "服务业"
}, {
    "children": [{
        "id": "7799fc794489494a8d11249f35ad68c3",
        "name": "制药/生物工程"
    }, {
        "id": "372547990b8d4a2da39bb514774bb6e6",
        "name": "医疗/护理/卫生"
    }, {
        "id": "f2aeccaf981d4b49a518b2ad25311b42",
        "name": "医疗设备/器械"
    }],
    "id": "648315941a18456c8401ae6da43ddf9c",
    "name": "制药/医疗"
}, {
    "children": [{
        "id": "2a597c36bbd246d8abf24eeb88dee043",
        "name": "会计/审计"
    }, {
        "id": "3329de9860aa445f82539a15bc34152f",
        "name": "金融/投资/证券"
    }, {
        "id": "a929753bc2f14b9384588f9a956f75d8",
        "name": "银行"
    }, {
        "id": "fd19de38d4ac4f32bf345f133e34a824",
        "name": "保险"
    }, {
        "id": "66c375294db64a55b2601059dd30f556",
        "name": "信托/担保/拍卖/典当"
    }],
    "id": "6effd471acd94289b64b13c8609f82de",
    "name": "会计/金融/银行/保险"
}, {
    "children": [{
        "id": "450ef46a15d647838480aa29b7125e97",
        "name": "交通/运输/物流"
    }, {
        "id": "71f8a9d5b61149f79e0e21a065f37843",
        "name": "航天/航空"
    }],
    "id": "15c8cb6b91ed4aca92703c310502f547",
    "name": "物流/运输"
}, {
    "children": [{
        "id": "5cb63979a44b47b08a7da5f83bc4d9a6",
        "name": "计算机软件"
    }, {
        "id": "aa7c61ac8a364ee0956c4d1cc775962d",
        "name": "计算机硬件"
    }, {
        "id": "ec6ef32506724ff08d13184db6e9db5d",
        "name": "计算机服务(系统、数据服务、维修)"
    }, {
        "id": "f4f9b9f2eb954cd8bc05dc34b6db160c",
        "name": "通信/电信/网络设备"
    }, {
        "id": "48291bec015c4a4499c7116708d44ba9",
        "name": "通信/电信运营、增值服务"
    }, {
        "id": "ffca981299ee4e3b8feab6adba9d1eb6",
        "name": "网络游戏"
    }, {
        "id": "ec79ab98258a4c86a6d7e78624c70eb6",
        "name": "电子技术/半导体/集成电路"
    }, {
        "id": "e485c99d41774beaa8ca174929cb6e60",
        "name": "互联网/电子商务"
    }, {
        "id": "07bdb47003d1416ca71641947324d6c0",
        "name": "仪器仪表/工业自动化"
    }],
    "id": "db4a043631174e3383834e1387a37a1a",
    "name": "计算机/互联网/通信/电子"
}, {
    "children": [{
        "id": "c5e55eece29541ddac5ec7773aaf70f3",
        "name": "专业服务(咨询、人力资源、财会)"
    }, {
        "id": "f2fd81adf81e4610942fa65ec3e9ca19",
        "name": "外包服务"
    }, {
        "id": "8963bb0197584f6087b651ebfd18f840",
        "name": "检测，认证"
    }, {
        "id": "ee8e5123a1a048b7a181574175ab91e5",
        "name": "法律"
    }, {
        "id": "82bf5f34fd6b4f13b92705d6a1417486",
        "name": "教育/培训/院校"
    }, {
        "id": "373892082a9049688d9b7cdaf46f80ec",
        "name": "学术/科研"
    }],
    "id": "065dee4502c748ab9e6ee045e03fa879",
    "name": "专业服务/教育/培训"
}, {
    "children": [{
        "id": "4c0b7b25e0574a4a953a063034c61812",
        "name": "政府/公共事业"
    }, {
        "id": "fc25d3e7b5964655b449e82d348437d4",
        "name": "非营利组织"
    }, {
        "id": "412fd0d0cb4e48fa965e26563e623701",
        "name": "农/林/牧/渔"
    }, {
        "id": "b10efc60653648a195080b52c9a5a322",
        "name": "多元化业务集团公司"
    }],
    "id": "0476d035066f4557b8c003fe76e39d63",
    "name": "政府/非营利组织/其他"
}, {
    "children": [{
        "id": "cdbb1b43e6924b6f8d61c0098861a898",
        "name": "广告"
    }, {
        "id": "1f8c4aa3643846db92da8aade2bcab06",
        "name": "公关/市场推广/会展"
    }, {
        "id": "7fd0caf43a564856980d79a3d41643a2",
        "name": "影视/媒体/艺术/文化传播"
    }, {
        "id": "473d5a1c6975420baa95ec6873a036f9",
        "name": "文字媒体/出版"
    }, {
        "id": "74b4713a4382439c807dfbc40b3377c2",
        "name": "印刷/包装/造纸"
    }],
    "id": "dcd6b47c1f5f43f288036e883583b3e5",
    "name": "广告/媒体"
}]

export default {
    enterType,
    enterHy
}