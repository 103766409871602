<template>
    <div>
      
        <div id="map_box" style="height: 300px;"></div>
    </div>
</template>

<script>
let myvue=null
window.init=e=>{
    myvue.codeAddress()
}
function deleteOverlays() {

    if (markersArray) {
        for (let i in markersArray) {
            markersArray[i].setMap(null);
        }
        markersArray.length = 0;
        markersArray = [];
    }

}
export default {
    props: ["address"],
    data() {
        return {
            gps_lng: 0,
            gps_lat: 0,
            priceList: [],
            map: null,
            markersArray: []
        }
    },
    mounted() {
        myvue = this
        this.initPage()
        

    },
    methods: {


        initPage() {
            this.loadMap();
        },



        loadMap() {
            if (!window.qq) {
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.src = "https://map.qq.com/api/js?v=2.exp&key=Y4KBZ-4EIWG-KCOQL-INNMW-WSHHJ-3JBIQ&callback=init";
                document.body.appendChild(script);
               
            } else {
                this.codeAddress()
            }


        },
        showGpsCenter() {

            if (!this.gps_lat || !this.gps_lng) {

            } else {
                let location = new qq.maps.LatLng(
                    parseFloat(this.gps_lat),
                    parseFloat(this.gps_lng)
                )
                //map.setCenter(location);
                var marker = new qq.maps.Marker({
                    map: map,
                    position: location
                });
                markersArray = [marker];
            }


        },
        codeAddress() {

            let geocoder = new qq.maps.Geocoder({
                complete: (result) => {

                    if (result.type == "GEO_INFO") {
                        var myLatlng = new qq.maps.LatLng(result.detail.location.lat, result.detail.location.lng);
                        var myOptions = {
                            zoom: 15, //设置地图缩放级别
                            center: myLatlng, //设置中心点样式
                            panControl: false,         //平移控件的初始启用/停用状态。      
                            zoomControl: false,       //缩放控件的初始启用/停用状态。
                            scaleControl: false,       //滚动控件的初始启用/停用状态。
                            disableDefaultUI:true,
                            mapTypeId: qq.maps.MapTypeId.ROADMAP //设置地图样式详情参见MapType
                        }
                        this.map = new qq.maps.Map(document.getElementById("map_box"), myOptions);
                       
                        this.map.setCenter(result.detail.location);
                        var marker = new qq.maps.Marker({
                            map: this.map,
                            position: result.detail.location
                        });
                        this.markersArray = [marker];

                    }


                }
            });
            geocoder.getLocation(this.address);

        },
        saveForm() {

        },

    }
}
</script>

<style scoped></style>
